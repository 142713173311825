<!-- MAKE SURE <footer> has class .footer--secondary -->
<app-contactus *ngIf="router.url!=='/home'"></app-contactus>
<footer class="footer footer--secondary">
    <div class="shell">
        <div class="footer__inner">
            <div class="footer__cols">
                <div class="footer__col">
                    <a routerLink="/home" class="footer__logo">
                       <img src="assets/images/logo--small@2x.png" alt="" width="87" height="36">
					
                    </a>
					
					
			
					
					
                </div>
				 <div class="footer__col">
                    <h6>Support</h6>
                    <nav class="footer__nav">
                        <ul>
                            <li *ngIf="token?.length>0">
                                <a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/HallmarkMediaHub_WebsiteUserSteps.pdf" target="_blank">How-To-Guide</a>
                            </li>			
                           <li *ngIf="token?.length>0">
                                <a href="mailto:info@hallmarkmediahub.com" target="_blank">Contact Us</a>
                            </li>
                            <li *ngIf="token?.length>0">
                                <a  routerLink="/support"  target="_blank">General Support</a>
                            </li> 
							<li>
								<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/Closed+Captioning+Certification+-+Q2+2024.pdf" target="_blank">FCC Closed Captioning Q2 2024</a>
							</li>
							<li>
								<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/FCC+Kids+2023.pdf" target="_blank">FCC Kids Q4 2023</a>
							</li>
                        </ul>
                    </nav>

                </div>


     
                <div class="footer__col">
                    <h6>Hallmark Network Sites</h6>

                    <nav class="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.hallmarkchannel.com/" target="_blank">Hallmark Channel</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkmoviesandmysteries.com/" target="_blank">Hallmark Mystery</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkfamily.com/" target="_blank">Hallmark Family</a>
                            </li>
                            <li>
                                <a href="https://www.hallmarkplus.com" target="_blank">Hallmark +</a>
                            </li>          
                        </ul>
                    </nav>
                </div>
                <div class="footer__col">
                    <h6>Hallmark Brands</h6>

                    <nav class="footer__nav">
                        <ul>
                            <li>
                                <a href="https://www.hallmark.com/" target="_blank">Hallmark Cards</a>
                            </li>
                            <li>
                                <a href="https://www.crayola.com/" target="_blank">Crayola</a>
                            </li>
                            <li>
                                <a href="https://www.dayspring.com/" target="_blank">Dayspring</a>
                            </li>  
							
                        </ul>
                    </nav>
                </div>
                <div class="footer__col">
                    <h6>Corporate</h6>
                    <nav class="footer__nav">
                        <ul>
							<li>
                                <a href="https://press.hallmarkmedia.com" target="_blank">Press</a>
                            </li>
                            <li>
                                <a href="https://www.hallmark.com/privacy/" target="_blank">Privacy Policy	</a>
                            </li>			
                            <li>
                                <a href="https://www.hallmark.com/terms-of-use/" target="_blank">Terms of Use</a>
                            </li>
                            <li>
                                <a href="https://www.hallmark.com/privacy-notice/" target="_blank">CA Privacy Notice</a>
                            </li>			
                             <li>
                                <a href="https://www.hallmarkchannel.com/accessibility-notice" target="_blank">Accessibility</a>
                            </li>       
                        </ul>
                    </nav>

                </div>

                    <p>Copyright © 2024 Hallmark Media, all rights reserved.</p>
            </div>
        </div>
    </div>
</footer>





				
		
			
			
			
		
			
		
		
			